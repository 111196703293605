import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

@Injectable({
  providedIn: 'root',
})
export class CommunicationMatrixService {
  private readonly isDelete$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  isDelete = this.isDelete$.asObservable();

  private readonly isCreate$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  isCreate = this.isCreate$.asObservable();

  private readonly isModified$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  isModified = this.isModified$.asObservable();
  private readonly meetingDelete$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  meetingDelete = this.meetingDelete$.asObservable();

  private readonly meetingCreate$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  meetingCreate = this.meetingCreate$.asObservable();

  private readonly meetingModified$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  meetingModified = this.meetingModified$.asObservable();
  constructor(private readonly http: HttpClient) {}
  getCommunicationList(projectCode: string) {
    return this.http
      .get(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?projectCode=${projectCode}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  createCommunication(communication: any) {
    return this.http
      .post(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?action=create`,
        communication
      )
      .pipe(
        map((res: any) => {
          return res.status.messages[0];
        })
      );
  }
  updateCommunication(communication: any) {
    return this.http
      .put(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?action=update`,
        communication
      )
      .pipe(
        map((res: any) => {
          return res.status.messages[0];
        })
      );
  }
  deletetCommunication(del: any) {
    return this.http
      .put(`${environment.baseUrl}${endpoint.COM_MATRIX}?action=delete`, del)
      .pipe(
        map((res: any) => {
          return res.status.messages[0];
        })
      );
  }

  getMeetingMin(projectCode: string, comName: string) {
    return this.http
      .get(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?projectCode=${projectCode}&communicationName=${comName}`
      )
      .pipe(
        map((res: any) => {
          return res.data == null ? [] : res.data.meetingMinutes;
        })
      );
  }
  createtMeetingMin(createRequest: any) {
    return this.http
      .post(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?action=create`,
        createRequest
      )
      .pipe(
        map((res: any) => {
          return res.status;
        })
      );
  }
  updateMeetingMin(meetingMin: any) {
    return this.http
      .put(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?action=update`,
        meetingMin
      )
      .pipe(
        map((res: any) => {
          return res.status;
        })
      );
  }

  getActionItem(): Observable<any[]> {
    const projectCode = sessionStorage.getItem('currentPrj');
    return this.http
      .get<any>(
        `${environment.baseUrl}${endpoint.COM_MATRIX}?projectCode=${projectCode}&fetch=actionItem`
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
  deleteMeetingMin(del: any) {
    return this.http
      .put(`${environment.baseUrl}${endpoint.COM_MATRIX}?action=delete`, del)
      .pipe(
        map((res: any) => {
          return res.status;
        })
      );
  }
  setCreate(value: any) {
    this.isCreate$.next(value);
  }
  setDelete(value: any) {
    this.isDelete$.next(value);
  }
  setModify(value: any) {
    this.isModified$.next(value);
  }

  setMeetingCreate(value: any) {
    this.meetingCreate$.next(value);
  }
  setMeetingDelete(value: any) {
    this.meetingDelete$.next(value);
  }
  setMeetingModify(value: any) {
    this.meetingModified$.next(value);
  }
}
