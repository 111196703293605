import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

import {
  ApiResponse,
  CreateRiskData,
  DeleteRiskData,
  Risk,
  RiskResponse,
} from './risk.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RiskService {
  correctPjcd: any;
  projectCode: any;
  riskEditData: any;
  public riskMessage = new BehaviorSubject('defalute');
  currentMessage = this.riskMessage.asObservable();
  constructor(private readonly httpClient: HttpClient) {}

  // This Function fetch the Risk Data
  getRiskData(projectCode: string): Observable<RiskResponse> {
    return this.httpClient
      .get<RiskResponse>(
        `${environment.baseUrl}${endpoint.PROJECT}?projectCode=${projectCode}&fetch=risk`
      )
      .pipe(
        map((res: RiskResponse) => {
          return res;
        })
      );
  }

  // This function is use to create Risk
  createRisk(reqData: CreateRiskData): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.baseUrl}${endpoint.Risk}`,
      reqData
    );
  }

  // This function is use to Edit Risk
  editRisk(reqData: CreateRiskData): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(
      `${environment.baseUrl}${endpoint.Risk}?action=update`,
      reqData
    );
  }
  // This function is use to Delete Risk
  deleteRisk(reqData: DeleteRiskData): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(
      `${environment.baseUrl}${endpoint.Risk}?action=delete`,
      reqData
    );
  }

  // This function is use to Store Selected Risk Data in service variable so other component can access it

  setRiskDataStore(data: Risk): void {
    sessionStorage.setItem('riskEditData', JSON.stringify(data));
  }
  // This function use to get selected risk data where ever it is required
  getRiskDataStore(): Risk {
    return JSON.parse(sessionStorage.getItem('riskEditData'));
  }
}
